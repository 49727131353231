// TODO: change import to @common/components/form when Option type will be available
import { Option } from '@/common/components/form-controls-deprecated';
import { Enum } from '../utils';

export const YesNoFilterValues = Enum('YES', 'NO');
export type YesNoFilterValues = Enum<typeof YesNoFilterValues>;

export const YesNoFilterOptions: Option[] = [
  { label: 'Yes', value: YesNoFilterValues.YES },
  { label: 'No', value: YesNoFilterValues.NO },
];
